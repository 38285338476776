<template>
  <card
    :content="content"
    :schema="getSchemaHomeCompounders"
    :form-data="getDataHomeCompounders"
    :editable="getEditableHomeCompounders"
    @update-form="updateHomeCompounders"
    @submitAction="addHomeCompounders"
  />
</template>

<script>
import Card from "../components/Card";
import confHomeCompounders from "../conf/confHomeCompounders.json";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "HomeCompounders",
  components: { Card },
  title() {
    const locationName =
      this.$store.getters["company/get_locationName"] ??
      localStorage.getItem("locationName");
    return this.$t(this.$route.meta.title) + " – " + locationName;
  },

  data() {
    return {
      content: confHomeCompounders.content,
      hidden: true
    };
  },
  created() {
    this.$store.dispatch("homeCompounders/loadHomeCompounders");
  },
  computed: {
    ...mapGetters("homeCompounders", [
      "getDataHomeCompounders",
      "getSchemaHomeCompounders",
      "getEditableHomeCompounders"
    ])
  },
  methods: {
    ...mapActions("homeCompounders", [
      "updateHomeCompounders",
      "addHomeCompounders",
      "loadHomeCompounders"
    ])
  }
};
</script>

<style scoped></style>
